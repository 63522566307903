<template>
  <div>
    <iframe :src="url"
            frameborder="0"
            width="100%"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "questionnaire",
  data() {
    return {
      url: null
    }
  },
  mounted() {
    let userName = JSON.parse(localStorage.getItem('userInfo')).userName
    this.url = 'https://gld.xxatis.com/diaowen/?name=' + userName
    let height = document.body.clientHeight
    document.getElementsByTagName('iframe')[0].height = height - 80
  }
}
</script>

<style scoped>

</style>